import store from '../store/index.js'
import { $post } from '@/api/http.js'

export default class RouterHelper {
	constructor() {
		this.menu = [] //菜单信息
	}
	/**
	 * 初始化获取权限菜单
	 * @param {Object} t	
	 * @param {Object} addons	板块 sass:web   小程序管理端：farm_admin
	 */
	getMenu(addons) {
		let t = this
		return new Promise((resolve, reject) => {
			$post("init",{addons},true,1).then(res=>{
				if (res.data.menu.length > 0) {
					let sessionBtn = JSON.parse(sessionStorage.getItem('__BTNPERM__'))
					//按钮权限sass端和应用管理端分别保存，不然从应用管理端返回sass端时，会出现应用无操作权限的bug
					let btn_perm = {
						web: sessionBtn ? sessionBtn.web : [],
						farm_admin: sessionBtn ? sessionBtn.farm_admin : [],
					}
					let d = t.neatemMenuData(res.data.menu)

					if (addons == 'web') {
						store.dispatch('setPermissionList', res.data.menu)
						btn_perm.web = d.menu_route
					}

					if (addons == 'farm_admin') {
						store.dispatch('setPermPlatform', res.data.menu)
						btn_perm.farm_admin = d.menu_route
					}

					store.dispatch('setPermRoute', d.menu_route)
					store.dispatch('setModulesPerm', res.data.module) //模块权限
					//缓存当前路由和按钮的权限到本地，以便以当用户点击浏览器强制刷新时，数据没有及时返回时，按钮权限不能控制
					sessionStorage.setItem('__BTNPERM__', JSON.stringify(btn_perm))
					resolve(d.menu)
				}
				reject('err')
			})
		})
	}

	/**
	 * 获取当前访问的平台的菜单和允许访问的前端路由
	 * @param {Object} perm	 后端返回的菜单信息
	 */
	neatemMenuData(perm) {
		let menu = [],
			menu_route = [],
			t = this;
		const _menu = (perm) => {
			perm.forEach(item => {
				if (item.menu_type == 1) {
					if (item.menu_parent == 0) { //顶级菜单
						menu.push(t.getItem(item))
					} else { //不是顶级时，就循环当前的菜单查找上级菜单
						menu.forEach((val, ind) => {
							if (item.menu_parent == val.menu_id) {
								menu[ind].children.push(t.getItem(item))
							} else {
								if (val.children) {
									val.children.forEach((v, i) => {
										if (item.menu_parent == v.menu_id) {
											menu[ind].children[i].children.push(t.getItem(item))
										}
									})
								}
							}
						})
					}
				}
				if (item.menu_route && !menu_route.includes(item.menu_route)) {
					menu_route.push(item.menu_route)
				}

				if (item.children) {
					_menu(item.children)
				}
			})
			return {
				menu,
				menu_route
			}
		}

		return _menu(perm)
	}

	getItem(item) {
		return {
			title: item.menu_name,
			path: item.menu_route,
			menu_id: item.menu_id,
			menu_icon: item.menu_icon,
			menu_parent: item.menu_parent,
			children: [],
            enable:item.enable,
            plugin:item.plugins
		}
	}


	/**
	 * 检测某个操作是否有权限
	 * @param {Object} perm		后端返回的菜单/权限
	 * @param {Object} menu_route	当前操作的唯一标识
	 */
	checkActionAuth(perm, menu_route) {
		let flag = false
		const getAciton = (perm, menu_route) => {
			for (let i of perm) {
				if (i.menu_route == menu_route) {
					flag = true;
				} else {
					if (flag) return true;
					i.children ? getAciton(i.children, menu_route) : false;
				}
			}
			return flag;
		}
		return getAciton(perm, menu_route)
	}

	/**
	 * 权限检测【根据路由判断当前进入的事哪个模块】
	 * @param {Object} path		可访问的权限路由
	 * @param {Object} modules	权限模块
	 */
	moduleAuthCheck(path, modules) {
		if (modules.multi_merchant == 0 && path.includes('/adhibition/store/')) return false
		if (modules.salesroom == 0 && path.includes('/adhibition/outlet/')) return false
		if (modules.sales_share == 0 && path.includes('/adhibition/distribution/')) return false
		if (modules.wholesale == 0 && path.includes('/wholesale/')) return false
		if (modules.marketing_9_lock_draw == 0 && path.includes('/marketing/lottery/')) return false
		if (modules.return_to_cash == 0 && path.includes('/marketing/cashback/')) return false

		return true
	}

	/**
	 * 获取某个路径的上级
	 * @param {Object} perm
	 */
	getPathParent(perm, path) {
		let first = '',
			second = '',
			third = '';
		for (var i = 0; i < perm.length; i++) {
			let arr1 = perm[i]
			if (arr1.menu_route == path) {
				first = path
				second = path
				break
			}
			if (arr1.children.length > 0) {
				for (var j = 0; j < arr1.children.length; j++) {
					let arr2 = arr1.children[j]
					if (arr2.menu_route == path) {
						first = arr1.menu_route
						second = path
						break
					}

					if (arr2.children.length > 0) {
						for (var n = 0; n < arr2.children.length; n++) {
							let arr3 = arr2.children[n]
							if (arr3.menu_route == path) {
								first = arr1.menu_route
								second = arr2.menu_route
								third = path
								break
							}
						}
					}
				}
			}
		}

		return {
			first,
			second,
			third
		}
	}



}
